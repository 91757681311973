<template>
  <div>
    <slot name="header" />
    <div class="container mx-auto py-12 max-w-lg">
      <radio-list
        :options="data.options"
        @input="handleUpdate"
        @continue="handleUpdate"
        option-value="title"
        option-key="title"
        v-model="data.model" />
    </div>

    <slot :is-valid="isValid" />
  </div>
</template>

<script>
import validate from "validate.js"

export default {
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  methods: {
    handleUpdate () {
      if (this.isValid) {
        this.$emit("continue")
      }
    }
  },

  computed: {
    modelErrors () {
      return validate.single(this.data.model, {
        presence: true,
        type: "string"
      })
    },

    isValid () {
      return (typeof this.modelErrors === "undefined")
    }
  }
}
</script>
